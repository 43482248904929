import * as Eq from 'fp-ts/Eq'
import * as Ord from 'fp-ts/Ord'
import * as A from 'fp-ts/ReadonlyArray'
import { pipe } from 'fp-ts/function'
import * as S from 'fp-ts/string'
import { OptionId, OptionValueId } from '@api/endpoints/buildings/options'
import { ordOrderNumber } from '@api/endpoints/files'

// Generic Types

type OptionValueLike = {
  optionValueId: OptionValueId
}

type OptionLike = {
  optionId: OptionId
  values: ReadonlyArray<OptionValueLike>
}

type OrderedOptionValueLike = OptionValueLike & {
  orderNumber: number
}

type OrderedOptionLike = OptionLike & {
  orderNumber: number
  values: ReadonlyArray<OrderedOptionValueLike>
}

type DisableableOrderedOptionValueLike = OrderedOptionValueLike & {
  disabled: boolean
}

type DisableableOrderedOptionLike = OrderedOptionLike & {
  disabled: boolean
  values: ReadonlyArray<DisableableOrderedOptionValueLike>
}

type OptionIdAndOptionValueId = OptionValueLike & {
  optionId: OptionId
}

// Eq

export const eqOptionValueId = S.Eq
export const eqOptionValueIdArray = A.getEq(eqOptionValueId)

export const eqOptionValueLikeByOptionValueId: Eq.Eq<OptionValueLike> = pipe(
  eqOptionValueId,
  Eq.contramap((o) => o.optionValueId)
)

export const eqOptionValueLikeArrayByOptionValueId = A.getEq(eqOptionValueLikeByOptionValueId)

// Ord

export const ordOrderedOptionLikeByOrderNumber = pipe(
  ordOrderNumber,
  Ord.contramap((o: OrderedOptionLike) => o.orderNumber)
)

export const ordOrderedOptionValueLikeByOrderNumber = pipe(
  ordOrderNumber,
  Ord.contramap((o: OrderedOptionValueLike) => o.orderNumber)
)

// utils

export const filterDisabledAndSortOptionValues = <T extends DisableableOrderedOptionValueLike>(
  as: ReadonlyArray<T>
): ReadonlyArray<T> =>
  pipe(
    as,
    A.filter((o) => !o.disabled),
    A.sort(ordOrderedOptionValueLikeByOrderNumber)
  )

export const filterDisabledAndSortOptions = <T extends DisableableOrderedOptionLike>(
  as: ReadonlyArray<T>
): ReadonlyArray<T> =>
  pipe(
    as,
    A.filter((o) => !o.disabled),
    A.sort(ordOrderedOptionLikeByOrderNumber),
    A.map((o) => ({
      ...o,
      values: filterDisabledAndSortOptionValues(o.values)
    }))
  )

export const flattenOption = (o: OptionLike): ReadonlyArray<OptionIdAndOptionValueId> =>
  pipe(
    o.values,
    A.map((v) => ({ ...v, optionId: o.optionId }))
  )

export const getOptionIds = <T extends OptionLike>(as: ReadonlyArray<T>): ReadonlyArray<OptionId> =>
  pipe(
    as,
    A.map((o: T) => o.optionId)
  )

export const getOptionValueIds = <T extends OptionLike>(as: ReadonlyArray<T>): ReadonlyArray<OptionValueId> =>
  pipe(
    as,
    A.flatMap((o: T) =>
      pipe(
        o.values,
        A.map((v) => v.optionValueId)
      )
    )
  )

export const getSortedOptionValueIds = <T extends OptionLike>(as: ReadonlyArray<T>): ReadonlyArray<OptionValueId> =>
  pipe(as, getOptionValueIds, A.sort(S.Ord))

// TODO: Try to use import { ap } from 'fp-ts/lib/Identity'
export const diffOptionValueLikeByOptionValueId = <T extends OptionValueLike>(
  a1: ReadonlyArray<T>,
  a2: ReadonlyArray<T>
): ReadonlyArray<T> => A.difference<T>(eqOptionValueLikeByOptionValueId)(a1, a2)
