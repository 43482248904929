import * as Ord from 'fp-ts/Ord'
import * as A from 'fp-ts/ReadonlyArray'
import { pipe } from 'fp-ts/function'
import * as N from 'fp-ts/number'
import { ImageFileEntity } from '.'

export const ordOrderNumber = N.Ord

export const ordImageFileEntityByOrderNumber = pipe(
  ordOrderNumber,
  Ord.contramap((o: ImageFileEntity) => o.orderNumber)
)

export const sortImageFileEntityByOrderByOrderNumber = A.sort(ordImageFileEntityByOrderNumber)
