/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryKey } from '@tanstack/react-query'
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendAndReceive, sendOnly } from '@api/utils'
import { IdObject } from '@api/endpoints/types'
import { UpdateImagesOrderRequest } from '@api/endpoints/buildings'
import { CreateBasicImageRequest, FileId } from '@api/endpoints/files'
import {
  CreateOptionRequest,
  CreateOptionValueRequest,
  Option,
  OptionCode,
  OptionId,
  OptionList,
  OptionValue,
  OptionValueId,
  OptionValueImages,
  OptionValueList
} from './types'

const optionsUrl = `${apiBaseUrl}/options`
const optionUrl = (optionId: OptionId): string => `${optionsUrl}/${optionId}`
const optionValuesUrl = (optionId: OptionId): string => `${optionUrl(optionId)}/values`
const optionValueUrl = (optionId: OptionId, optionValueId: OptionValueId): string =>
  `${optionValuesUrl(optionId)}/${optionValueId}`
const optionValueImagesUrl = (optionId: OptionId, optionValueId: OptionValueId): string =>
  `${optionValueUrl(optionId, optionValueId)}/images`
const optionValueImagesOrderUrl = (optionId: OptionId, optionValueId: OptionValueId): string =>
  `${optionValueImagesUrl(optionId, optionValueId)}/order`
const optionValueImageFileUrl = (optionId: OptionId, optionValueId: OptionValueId, fileId: FileId): string =>
  `${optionValueImagesUrl(optionId, optionValueId)}/${fileId}`

export const queryKeys = {
  getOptionsAll: ['getOptionsAll'],
  getOptions: (code: OptionCode) => [...queryKeys.getOptionsAll, code],

  getOptionAny: ['getOption'],
  getOption: (optionId: OptionId): QueryKey => [...queryKeys.getOptionAny, optionId],

  getOptionValueAny: ['getOptionValue'],
  getOptionValue: (optionId: OptionId): QueryKey => [...queryKeys.getOptionValueAny, optionId],

  getOptionValuesAll: ['getOptionValues'],
  getOptionValues: (optionId: OptionId): QueryKey => [...queryKeys.getOptionValuesAll, optionId],

  getOptionValueImagesAll: ['getOptionValueImages'],
  getOptionValueImages: (optionId: OptionId, optionValueId: OptionValueId): QueryKey => [
    ...queryKeys.getOptionValueImagesAll,
    optionId,
    optionValueId
  ]
}

export const useGetOptions = (code: OptionalType<OptionCode>, opts?: ApiQueryOpts<OptionList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: optionsUrl,
        params: { code },
        responseSchema: OptionList
      }),
    // TODO: Is it a problem?
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: code ? queryKeys.getOptions(code) : queryKeys.getOptionsAll,
    ...opts
  })

export const useGetOption = (optionId: OptionId, opts?: ApiQueryOpts<Option>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: optionUrl(optionId),
        responseSchema: Option
      }),
    queryKey: queryKeys.getOption(optionId),
    ...opts
  })

export const useCreateOption = (opts?: ApiMutationOpts<IdObject, CreateOptionRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: optionsUrl,
        requestSchema: CreateOptionRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getOptionAny, queryKeys.getOptionsAll],
    ...opts
  })

export const useUpdateOption = (id: OptionId, opts?: ApiMutationOpts<void, CreateOptionRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendOnly({
        method: 'put',
        url: optionUrl(id),
        requestSchema: CreateOptionRequest,
        data
      }),
    invalidateQueries: [queryKeys.getOptionAny, queryKeys.getOptionsAll],
    ...opts
  })

export const useDeleteOption = (opts?: ApiMutationOpts<void, OptionId>) =>
  useApiMutation({
    mutationFn: (id) => callOnly({ method: 'delete', url: optionUrl(id) }),
    invalidateQueries: [queryKeys.getOptionsAll],
    ...opts
  })

export const useGetOptionValues = (optionId: OptionId, opts?: ApiQueryOpts<OptionValueList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: optionValuesUrl(optionId),
        responseSchema: OptionValueList
      }),
    queryKey: queryKeys.getOptionValues(optionId),
    ...opts
  })

export const useCreateOptionValue = (optionId: OptionId, opts?: ApiMutationOpts<IdObject, CreateOptionValueRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: optionValuesUrl(optionId),
        requestSchema: CreateOptionValueRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getOptionValuesAll, queryKeys.getOptionValueAny],
    ...opts
  })

type UpdateOptionValueRequest = CreateOptionValueRequest & {
  id: OptionValueId
}

export const useUpdateOptionValue = (optionId: OptionId, opts?: ApiMutationOpts<void, UpdateOptionValueRequest>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: optionValueUrl(optionId, id),
        requestSchema: CreateOptionValueRequest,
        data
      }),
    invalidateQueries: [queryKeys.getOptionValuesAll, queryKeys.getOptionValueAny],
    ...opts
  })

export const useGetOptionValue = (optionId: OptionId, optionValueId: OptionValueId, opts?: ApiQueryOpts<OptionValue>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: optionValueUrl(optionId, optionValueId),
        responseSchema: OptionValue
      }),
    // TODO: Is it a problem?
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.getOptionValue(optionId),
    ...opts
  })

export const useDeleteOptionValue = (
  optionId: OptionId,
  optionValueId: OptionValueId,
  opts?: ApiMutationOpts<void, void>
) =>
  useApiMutation({
    mutationFn: () => callOnly({ method: 'delete', url: optionValueUrl(optionId, optionValueId) }),
    invalidateQueries: [queryKeys.getOptionValuesAll, queryKeys.getOptionValueAny, queryKeys.getOptionValueImagesAll],
    ...opts
  })

export const useGetOptionValueImages = (
  optionId: OptionId,
  optionValueId: OptionValueId,
  opts?: ApiQueryOpts<OptionValueImages>
) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: optionValueImagesUrl(optionId, optionValueId),
        responseSchema: OptionValueImages
      }),
    queryKey: queryKeys.getOptionValueImages(optionId, optionValueId),
    ...opts
  })

type CreateOptionValueImageRequest = CreateBasicImageRequest & {
  optionValueId: OptionValueId
}

export const useCreateOptionValueImage = (
  optionId: OptionId,
  opts?: ApiMutationOpts<IdObject, CreateOptionValueImageRequest>
) =>
  useApiMutation({
    mutationFn: ({ optionValueId, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: optionValueImagesUrl(optionId, optionValueId),
        requestSchema: CreateBasicImageRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getOptionValueImagesAll],
    ...opts
  })

export const useDeleteOptionValueImage = (
  optionId: OptionId,
  optionValueId: OptionValueId,
  opts?: ApiMutationOpts<void, FileId>
) =>
  useApiMutation({
    mutationFn: (fileId) =>
      callOnly({
        method: 'delete',
        url: optionValueImageFileUrl(optionId, optionValueId, fileId)
      }),
    invalidateQueries: [queryKeys.getOptionValueImagesAll],
    ...opts
  })

export const useUpdateOptionValueImagesOrder = (
  optionId: OptionId,
  optionValueId: OptionValueId,
  opts?: ApiMutationOpts<void, UpdateImagesOrderRequest>
) =>
  useApiMutation({
    mutationFn: (data) =>
      sendOnly({
        method: 'put',
        url: optionValueImagesOrderUrl(optionId, optionValueId),
        requestSchema: UpdateImagesOrderRequest,
        data
      }),
    invalidateQueries: [queryKeys.getOptionValueImagesAll],
    ...opts
  })
