import * as z from 'zod'
import { PositiveInt } from '@api/endpoints/types'
import { FileId } from '@api/endpoints/files'

export const ImageOrderedItem = z.object({
  item: FileId,
  index: PositiveInt
})
export type ImageOrderedItem = z.infer<typeof ImageOrderedItem>

export const UpdateImagesOrderRequest = z.object({
  items: ImageOrderedItem.array()
})
export type UpdateImagesOrderRequest = z.infer<typeof UpdateImagesOrderRequest>
