import * as z from 'zod'
import { StringToDate } from '@digital-magic/react-common/lib/utils/zod'
import { ImageFileEntity, TranslatedFileId } from '../../files'
import { AllowedAction, TranslatedString } from '../../types'

export const OptionId = z.string().uuid()
export type OptionId = z.infer<typeof OptionId>

export const OptionCode = z.string().min(2).max(20)
export type OptionCode = z.infer<typeof OptionCode>

export const OptionName = z.string().min(2).max(40)
export type OptionName = z.infer<typeof OptionName>

export const Option = z.object({
  id: OptionId,
  code: OptionCode,
  name: TranslatedString(OptionName),
  multiValue: z.boolean(),
  allowedActions: z.array(AllowedAction),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type Option = z.infer<typeof Option>

export const OptionListItem = z.object({
  id: OptionId,
  code: OptionCode,
  name: TranslatedString(OptionName),
  multiValue: z.boolean(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type OptionListItem = z.infer<typeof OptionListItem>

export const OptionList = z.array(OptionListItem)
export type OptionList = z.infer<typeof OptionList>

// TODO: Rename it, because used not only for creation
export const CreateOptionRequest = z.object({
  code: OptionCode,
  name: TranslatedString(OptionName),
  multiValue: z.boolean()
})
export type CreateOptionRequest = z.infer<typeof CreateOptionRequest>

export const OptionValueId = z.string().uuid()
export type OptionValueId = z.infer<typeof OptionValueId>

export const OptionValueName = z.string().min(2).max(40)
export type OptionValueName = z.infer<typeof OptionValueName>

export const OptionValueDescription = z.string().min(2).max(1000)
export type OptionValueDescription = z.infer<typeof OptionValueDescription>

export const OptionValue = z.object({
  id: OptionValueId,
  name: TranslatedString(OptionValueName),
  description: TranslatedString(OptionValueDescription).optional(),
  documentId: TranslatedFileId.optional(),
  allowedActions: z.array(AllowedAction),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type OptionValue = z.infer<typeof OptionValue>

export const OptionValueListItem = z.object({
  id: OptionValueId,
  name: TranslatedString(OptionValueName),
  description: TranslatedString(OptionValueDescription).optional(),
  documentId: TranslatedFileId.optional(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})

export const OptionValueList = z.array(OptionValueListItem)
export type OptionValueList = z.infer<typeof OptionValueList>

export const CreateOptionValueRequest = z.object({
  name: TranslatedString(OptionValueName),
  description: TranslatedString(OptionValueDescription).optional(),
  documentId: TranslatedFileId.optional()
})
export type CreateOptionValueRequest = z.infer<typeof CreateOptionValueRequest>

export const OptionValueImageListItem = ImageFileEntity
export type OptionValueImageListItem = z.infer<typeof OptionValueImageListItem>

export const OptionValueImages = OptionValueImageListItem.array()
export type OptionValueImages = z.infer<typeof OptionValueImages>
