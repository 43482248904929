import * as z from 'zod'
import { PositiveInt } from '@api/endpoints/types'

export const FileId = z.string().uuid()
export type FileId = z.infer<typeof FileId>

export const TranslatedFileId = z.object({
  eng: FileId,
  est: FileId,
  rus: FileId
})
export type TranslatedFileId = z.infer<typeof TranslatedFileId>

export const TranslatedFile = z.object({
  eng: z.instanceof(File),
  est: z.instanceof(File),
  rus: z.instanceof(File)
})
export type TranslatedFile = z.infer<typeof TranslatedFile>

export const TranslatedFileList = z.object({
  eng: z.instanceof(FileList),
  est: z.instanceof(FileList),
  rus: z.instanceof(FileList)
})
export type TranslatedFileList = z.infer<typeof TranslatedFileList>

export const UploadFileResponse = z.object({
  id: FileId
})
export type UploadFileResponse = z.infer<typeof UploadFileResponse>

export const BasicImageFileEntity = z.object({
  id: FileId,
  thumbnailSmallFileId: FileId.optional(),
  thumbnailLargeFileId: FileId.optional()
})
export type BasicImageFileEntity = z.infer<typeof BasicImageFileEntity>

export const ImageFileEntity = BasicImageFileEntity.extend({
  orderNumber: PositiveInt
})
export type ImageFileEntity = z.infer<typeof ImageFileEntity>

export const CreateBasicImageRequest = z.object({
  fileId: FileId
})
export type CreateBasicImageRequest = z.infer<typeof CreateBasicImageRequest>

export const CreateImageWithPrimary = CreateBasicImageRequest.extend({
  primaryImage: z.boolean().optional()
})
export type CreateImageWithPrimary = z.infer<typeof CreateImageWithPrimary>
